const getIsPromoDate = () => {
  const currentDate = new Date();
  const startDate = new Date('2025-02-03');
  const endDate = new Date('2025-02-17');

  return currentDate >= startDate && currentDate <= endDate;
};

export default {
  getIsPromoDate,
};

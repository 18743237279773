/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
function importFragment(fragment) {
  try {
    return require(`./${process.env.GATSBY_LOCALE}/${fragment}`).default;
  } catch {
    return require(`./default/${fragment}`).default;
  }
}

const FAQItems = importFragment('FAQItems');
const FAQassessmentItems = importFragment('FAQassessmentItems');
const PrivacyPolicy = importFragment('PrivacyPolicy');
const PrivacyPolicyMobile = importFragment('PrivacyPolicyMobile');
const ToS = importFragment('ToS');
const ToSMobile = importFragment('ToSMobile');
const LanguageSelect = importFragment('LanguageSelect');
const navItems = importFragment('navItems');
const block2Card = importFragment('block2Card');
const appScreenshots = importFragment('appScreenshots');
const shopScreenshots = importFragment('shopScreenshots');
const HubspotCouponForm = importFragment('HubspotCouponForm');
const HubspotCouponForm2024 = importFragment('HubspotCouponForm2024');
const HubspotInstitutionsForm = importFragment('HubspotInstitutionsForm');
const KokolingoGiftPage = importFragment('KokolingoGiftPage');
const blogArticles = importFragment('blogArticles');
const SpeechDevelopmentPage = importFragment('SpeechDevelopmentPage');
const OnlineSpeechTherapyExercisesPage = importFragment(
  'OnlineSpeechTherapyExercisesPage'
);
const DistanceSpeechTherapyPage = importFragment('DistanceSpeechTherapyPage');
const SpeechDisorderPage = importFragment('SpeechDisorderPage');
const Banner = importFragment('Banner');
const blogHome = importFragment('blogHome');
const PricingTable = importFragment('PricingTable');
const Sounds = importFragment('sounds');
const BlogLastArticles = importFragment('blogLastArticles');
const SocialMedia = importFragment('SocialMedia');
const marketingScripts = importFragment('marketingScripts');
const AshaFragment = importFragment('AshaFragment');
const GameFragment = importFragment('GameFragment');
const Activities = importFragment('activities');
const BlogArticles = importFragment('BlogArticles');
const FAQ = importFragment('FAQ');
const Media = importFragment('Media');
const MediaItems = importFragment('MediaItems');
const ModalPromo = importFragment('ModalPromo');
const ModalRegister = importFragment('ModalRegister');
const Page = importFragment('page');
const Partners = importFragment('Partners');
const PartnersItems = importFragment('PartnersItems');
const RegisterForm = importFragment('RegisterForm');
const SectionHeader = importFragment('SectionHeader');
const TopBar = importFragment('TopBar');
const SectionIntro = importFragment('SectionIntro');
const SectionShortAbout = importFragment('SectionShortAbout');
const SectionSounds = importFragment('SectionSounds');
const SectionActivities = importFragment('SectionActivities');
const SectionVideoAbout = importFragment('SectionVideoAbout');
const SectionFooter = importFragment('SectionFooter');
const SectionCustomers = importFragment('SectionCustomers');
const SectionWhyFree = importFragment('SectionWhyFree');
const SectionPricing = importFragment('SectionPricing');
const SectionHowToStart = importFragment('SectionHowToStart');
const SectionInstitutions = importFragment('SectionInstitutions');

const SectionMedia = importFragment('SectionMedia');
const SectionFAQ = importFragment('SectionFAQ');
const SectionBlog = importFragment('SectionBlog');
const FooterNew = importFragment('FooterNew');
const FAQnew = importFragment('FAQnew');
const CardPost = importFragment('CardPost');
const PageNew = importFragment('PageNew');
const WhyCard = importFragment('WhyCard');
const Footer = importFragment('Footer');
const FooterHeader = importFragment('FooterHeader');
const TeletherapyHeader = importFragment('Teletherapy/header');
const TeletherapyIntro = importFragment('Teletherapy/intro');
const TeletherapyGuarantee = importFragment('Teletherapy/guarantee');
const TeletherapyPricing = importFragment('Teletherapy/pricing');
const TeletherapyReviews = importFragment('Teletherapy/reviews');
const AcademyHeader = importFragment('Academy/header');
const AcademyIntro = importFragment('Academy/intro');
const AcademyDetails = importFragment('Academy/details');
const AcademyFAQ = importFragment('Academy/faq');
const AcademyPricing = importFragment('Academy/pricing');
const AcademyAdditionalInfo = importFragment('Academy/additionalInfo');
const AcademyReviews = importFragment('Academy/reviews');
const AcademyInstitutionHeader = importFragment('AcademyInstitution/header');
const AcademyInstitutionIntro = importFragment('AcademyInstitution/intro');
const AcademyInstitutionDetails = importFragment('AcademyInstitution/details');
const AcademyInstitutionFAQ = importFragment('AcademyInstitution/faq');
const AcademyInstitutionPricing = importFragment('AcademyInstitution/pricing');
const AcademyInstitutionAdditionalInfo = importFragment(
  'AcademyInstitution/additionalInfo'
);
const AcademyInstitutionReviews = importFragment('AcademyInstitution/reviews');
const CouponsHeader = importFragment('Coupons/header');
const CouponsIntro = importFragment('Coupons/intro');
const CouponsPricing = importFragment('Coupons/pricing');
const CouponsForm = importFragment('Coupons/form');

export {
  AshaFragment,
  GameFragment,
  FAQItems,
  FAQassessmentItems,
  LanguageSelect,
  navItems,
  block2Card,
  PrivacyPolicy,
  PrivacyPolicyMobile,
  ToS,
  ToSMobile,
  appScreenshots,
  shopScreenshots,
  HubspotCouponForm,
  HubspotCouponForm2024,
  HubspotInstitutionsForm,
  KokolingoGiftPage,
  Banner,
  blogArticles,
  SpeechDevelopmentPage,
  OnlineSpeechTherapyExercisesPage,
  DistanceSpeechTherapyPage,
  SpeechDisorderPage,
  blogHome,
  PricingTable,
  Sounds,
  BlogLastArticles,
  SocialMedia,
  marketingScripts,
  Activities,
  BlogArticles,
  FAQ,
  Media,
  MediaItems,
  ModalPromo,
  ModalRegister,
  Page,
  Partners,
  PartnersItems,
  RegisterForm,
  SectionHeader,
  TopBar,
  SectionIntro,
  SectionShortAbout,
  SectionSounds,
  SectionActivities,
  SectionVideoAbout,
  SectionFooter,
  SectionCustomers,
  SectionWhyFree,
  SectionPricing,
  SectionHowToStart,
  SectionMedia,
  SectionFAQ,
  SectionBlog,
  SectionInstitutions,
  FooterNew,
  FAQnew,
  CardPost,
  PageNew,
  WhyCard,
  Footer,
  FooterHeader,
  TeletherapyHeader,
  TeletherapyIntro,
  TeletherapyGuarantee,
  TeletherapyPricing,
  TeletherapyReviews,
  AcademyHeader,
  AcademyIntro,
  AcademyDetails,
  AcademyFAQ,
  AcademyPricing,
  AcademyAdditionalInfo,
  AcademyReviews,
  AcademyInstitutionHeader,
  AcademyInstitutionIntro,
  AcademyInstitutionDetails,
  AcademyInstitutionPricing,
  AcademyInstitutionFAQ,
  AcademyInstitutionAdditionalInfo,
  AcademyInstitutionReviews,
  CouponsHeader,
  CouponsIntro,
  CouponsPricing,
  CouponsForm,
};
